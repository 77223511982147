.ng-select.ng-select-opened>.ng-select-container {
	background: #fff;
	border-color: #b3b3b3 #ccc #d9d9d9;
}

.ng-select.ng-select-opened>.ng-select-container:hover {
	box-shadow: none;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
	// top: -2px;
	border: solid #434345;
	// border-width: 0 0.3125rem 5px;
	border-width: 0 2px 2px 0;
	transform: rotate(-135deg);
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
	border-color: transparent transparent #434345;
}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.ng-select.ng-select-opened.ng-select-right>.ng-select-container {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.ng-select.ng-select-opened.ng-select-left>.ng-select-container {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
	// box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
	//   0 0 0 0.1875remrgba(0, 126, 255, 0.1);
}

.ng-select.ng-select-disabled>.ng-select-container {
	background-color: #f9f9f9;
}

.ng-select .ng-has-value .ng-placeholder {
	display: none;
}

.ng-select .ng-select-container {
	color: #434345;
	background-color: var(--ion-color-gray-20);
	border-radius: 4px;
	border: 1px solid #ccc;
	min-height: 50px;
	align-items: center;
}

.ng-select .ng-select-container:hover {
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.ng-select .ng-select-container .ng-value-container {
	align-items: center;
	padding-left: 0.9375rem;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
	padding-right: 0.9375rem;
	padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
	color: var(--ion-color-secondary-black);
}

.ng-select.ng-select-single .ng-select-container {
	height: 50px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	top: 0.3125rem;
	left: 0;
	padding-left: 0.625rem;
	padding-right: 3.125rem;
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	padding-right: 0.625rem;
	padding-left: 3.125rem;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
	background-color: #f9f9f9;
	border: 1px solid #e6e6e6;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
	padding: 0 0.3125rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
	padding-top: 0.3125rem;
	padding-left: 0.4375rem;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
	padding-right: 0.4375rem;
	padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	font-size: 0.9rem;
	margin-bottom: 0.3125rem;
	color: #434345;
	background-color: var(--ion-color-gray-40);
	border-radius: 2px;
	margin-right: 0.3125rem;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	margin-right: 0;
	margin-left: 0.3125rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
	background-color: #f9f9f9;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
	padding-left: 0.3125rem;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
	padding-left: 0;
	padding-right: 0.3125rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
	display: inline-block;
	padding: 0.0625rem 0.3125rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
	display: inline-block;
	padding: 0.0625rem 0.3125rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
	background-color: #d1e8ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
	border-right: 1px solid #b8dbff;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
	border-left: 1px solid #b8dbff;
	border-right: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
	// border-left: 1px solid #b8dbff;
	color: #38C2D4 !important;
	font-size: 0.75rem !important;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
	border-left: 0;
	border-right: 1px solid #b8dbff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
	padding: 0 0 0.1875rem 0.1875rem;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
	padding: 0 0.1875rem 0.1875rem 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
	color: #000;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	top: 0.3125rem;
	padding-bottom: 0.3125rem;
	padding-left: 0.1875rem;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	padding-right: 0.1875rem;
	padding-left: 0;
}

.ng-select .ng-clear-wrapper {
	color: var(--ion-color-secondary-black)
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
	color: #d0021b;
}

.ng-select .ng-spinner-zone {
	padding: 0.3125rem 0.3125rem 0 0;
}

[dir="rtl"] .ng-select .ng-spinner-zone {
	padding: 0.3125rem 0 0 0.3125rem;
}

.ng-select .ng-arrow-wrapper {
	width: 1.5625rem;
	padding-right: 1.25rem;
	padding-bottom: 1rem;
	margin-top: 8px;
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
	padding-left: 0.3125rem;
	padding-right: 0;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
	border-top-color: #666;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
	//border-color: #999 transparent transparent;

	border: solid #434345;
	border-width: 0 2px 2px 0;
	padding: 0.1875rem;
	transform: rotate(45deg);
	// border: solid black;
	// border-width: 0 3px 3px 0;
	// padding: 0.1875rem ;
	// transform: rotate(45deg);
}

.ng-dropdown-panel {
	background-color: var(--ion-color-gray-20);
	border: 1px solid #ccc;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	left: 0;
}

.ng-dropdown-panel.ng-select-top {
	bottom: 100%;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-color: #e6e6e6;
	margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}

.ng-dropdown-panel.ng-select-right {
	left: 100%;
	top: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-color: #e6e6e6;
	margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
	border-top-right-radius: 4px;
}

.ng-dropdown-panel.ng-select-bottom {
	top: 100%;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-top-color: #e6e6e6;
	margin-top: -1px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.ng-dropdown-panel.ng-select-left {
	left: -100%;
	top: 0;
	border-top-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-color: #e6e6e6;
	margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
	border-top-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-header {
	border-bottom: 1px solid #ccc;
	padding: 0.3125rem 0.4375rem;
}

.ng-dropdown-panel .ng-dropdown-footer {
	border-top: 1px solid #ccc;
	padding: 0.3125rem 0.4375rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
	user-select: none;
	padding: 0.5rem 0.625rem;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.54);
	cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
	cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
	background-color: var(--ion-color-gray-30);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
	color: rgba(0, 0, 0, 0.54);
	background-color: var(--ion-color-gray-40);
	font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	background-color: var(--ion-color-gray-20);
	color: rgba(0, 0, 0, 0.87);
	padding: 0.5rem 0.625rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	color: #434345;
	background-color: var(--ion-color-gray-40);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
	font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
	background-color: var(--ion-color-gray-30);
	color: #434345;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
	color: #ccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
	padding-left: 1.375rem;
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
	padding-right: 1.375rem;
	padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
	font-size: 80%;
	font-weight: 400;
	padding-right: 0.3125rem;
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
	padding-left: 0.3125rem;
	padding-right: 0;
}

[dir="rtl"] .ng-dropdown-panel {
	direction: rtl;
	text-align: right;
}