/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import 'theme/variables.scss';
@import 'theme/typography.scss';
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./assets/css/feather.css";
@import "./theme/dynamic-class.scss";
// @import url(//fonts.googleapis.com/css?family=Noto+Sans);
@import "./assets/css/Noto_Sans.css";
@import "./theme/mixins.scss";

* {
	font-family: 'Montserrat', sans-serif !important;
}

.mg-t-0 {
	margin-top: 0;
}

.mg-t-5 {
	margin-top: 0.3125rem;
}

.mg-b-5 {
	margin-bottom: 0.3125rem;
}

.mg-l-5 {
	margin-left: 0.3125rem;
}

.mg-r-5 {
	margin-right: 0.3125rem;
}

.mg-t-10 {
	margin-top: 0.625rem;
}

.mg-b-10 {
	margin-bottom: 0.625rem;
}

.mg-b-12 {
	margin-bottom: 0.75rem;
}

.mg-l-10 {
	margin-left: 0.625rem;
}

.mg-r-10 {
	margin-right: 0.625rem;
}

.mg-t-15 {
	margin-top: 0.9375rem;
}

.mg-b-15 {
	margin-bottom: 0.9375rem;
}

.mg-l-15 {
	margin-left: 0.9375rem;
}

.mg-r-15 {
	margin-right: 0.9375rem;
}

.mg-t-20 {
	margin-top: 1.25rem;
}

.mg-b-20 {
	margin-bottom: 1.25rem;
}

.mg-l-20 {
	margin-left: 1.25rem;
}

.mg-r-20 {
	margin-right: 1.25rem;
}

.mg-t-25 {
	margin-top: 1.5625rem;
}

.mg-b-25 {
	margin-bottom: 1.5625rem;
}

.mg-l-25 {
	margin-left: 1.5625rem;
}

.mg-r-25 {
	margin-right: 1.5625rem;
}

.mg-t-30 {
	margin-top: 1.875rem;
}

.mg-b-30 {
	margin-bottom: 1.875rem;
}

.mg-l-30 {
	margin-left: 1.875rem;
}

.mg-r-30 {
	margin-right: 1.875rem;
}

.mg-t-40 {
	margin-top: 2.5rem;
}

.mg-b-40 {
	margin-bottom: 2.5rem;
}

.mg-l-40 {
	margin-left: 2.5rem;
}

.mg-r-40 {
	margin-right: 2.5rem;
}

.mg-t-50 {
	margin-top: 3.125rem;
}

.mg-b-50 {
	margin-bottom: 3.125rem;
}

.mg-l-50 {
	margin-left: 3.125rem;
}

.mg-r-50 {
	margin-right: 3.125rem;
}

.pd-0 {
	padding: 0;
}

.pd-t-5 {
	padding-top: 0.3125rem;
}

.pd-b-5 {
	padding-bottom: 0.3125rem;
}

.pd-l-5 {
	padding-left: 0.3125rem;
}

.pd-r-5 {
	padding-right: 0.3125rem;
}

.pd-t-10 {
	padding-top: 0.625rem;
}

.pd-b-10 {
	padding-bottom: 0.625rem;
}

.pd-b-12 {
	padding-bottom: 0.625rem;
}

.pd-l-10 {
	padding-left: 0.625rem;
}

.pd-r-10 {
	padding-right: 0.625rem;
}

.pd-t-15 {
	padding-top: 0.9375rem;
}

.pd-b-15 {
	padding-bottom: 0.9375rem;
}

.pd-l-15 {
	padding-left: 0.9375rem;
}

.pd-r-15 {
	padding-right: 0.9375rem;
}

.pd-t-20 {
	padding-top: 1.25rem;
}

.pd-b-20 {
	padding-bottom: 1.25rem;
}

.pd-l-20 {
	padding-left: 1.25rem;
}

.pd-r-20 {
	padding-right: 1.25rem;
}

.pd-t-30 {
	padding-top: 1.875rem;
}

.pd-b-30 {
	padding-bottom: 1.875rem;
}

.pd-l-30 {
	padding-left: 1.875rem;
}

.pd-r-30 {
	padding-right: 1.875rem;
}

.pd-t-40 {
	padding-top: 2.5rem;
}

.pd-b-40 {
	padding-bottom: 2.5rem;
}

.pd-l-40 {
	padding-left: 2.5rem;
}

.pd-r-40 {
	padding-right: 2.5rem;
}

.pd-t-50 {
	padding-top: 3.125rem;
}

.pd-b-50 {
	padding-bottom: 3.125rem;
}

.pd-l-50 {
	padding-left: 3.125rem;
}

.pd-r-50 {
	padding-right: 3.125rem;
}

.pd-b-70 {
	padding-bottom: 4.375rem;
}

.pd-l-70 {
	padding-left: 4.375rem;
}

.pd-r-70 {
	padding-right: 4.375rem;
}

.pd-t-70 {
	padding-top: 4.375rem;
}

.pd-t-80 {
	padding-top: 5rem;
}

.pd-b-80 {
	padding-bottom: 5rem;
}

.pd-l-75 {
	padding-left: 4.6875rem;
}

.pd-l-80 {
	padding-left: 5rem;
}

.pd-r-75 {
	padding-right: 4.6875rem;
}

.pd-r-80 {
	padding-right: 5rem;
}

.w-100 {
	width: 100%
}

.mw-50 {
	min-width: 3.125rem;
}

.mw-70 {
	min-width: 4.375rem;
}

.mw-80 {
	min-width: 5rem;
}

.mw-90 {
	min-width: 90px;
}

.mw-100 {
	min-width: 100px;
}

.mw-120 {
	min-width: 120px;
}

.mw-140 {
	min-width: 140px;
}

.mw-150 {
	min-width: 150px;
}

.mw-160 {
	min-width: 160px;
}

.mw-170 {
	min-width: 170px;
}

.mw-200 {
	min-width: 200px;
}

.mw-220 {
	min-width: 220px;
}

.mw-250 {
	min-width: 250px;
}

.mw-310 {
	min-width: 310px;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.justify-end {
	justify-content: flex-end;
}

.justify-start {
	justify-content: flex-start;
}

.bg-primary {
	background: var(--ion-app-primary);
}

.bg-grey {
	background: var(--ion-grey2);
}

.btn-green {
	background: var(--green-btn-bg);
	color: var(--ion-color-green-100);
}

.btn-black {
	background: var(--black-bg-btn);
	color: var(--ion-color-primary-black);
}

.btn-red {
	background: var(--red-btn-bg);
	color: var(--ion-color-red-100);
}

.btn-blue {
	background: var(--blue-btn-bg);
	color: var(--ion-color-primary);
}

.btn-grey {
	background: var(--grey-btn-bg);
	color: var(--ion-color-primary-black);
}

.btn-dark-blue {
	background: var(--dark-blue-bg-btn);
	color: var(--ion-color-primary-contrast);
}

.btn-orange {
	background: var(--orange-btn-bg);
	color: var(--ion-app-light-orange);
}

ion-content {
	--offset-bottom: auto !important;
	--overflow: hidden;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar:horizontal {
		height: 0.3125rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--ion-divider);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffffff;
		border-radius: 10px;
	}
}

ion-spinner {
	--color: var(--ion-color-primary);
}

ion-button {
	ion-spinner {
		--color: var(--ion-color-primary-white);
	}

}

// add style on focus in searchbar
.searchbar-input.sc-ion-searchbar-md:focus {
	border-width: 2px !important;
}

// add pointer on search icon
.searchbar-search-icon.sc-ion-searchbar-md {
	cursor: pointer;
	pointer-events: unset;
}

ion-searchbar {
	.searchbar-search-icon.sc-ion-searchbar-md {
		cursor: unset;
		pointer-events: unset;
		display: none;
		visibility: hidden;
	}

	.searchbar-input.sc-ion-searchbar-md {
		padding: 0.9375rem 3.75rem 0.9375rem 0.9375rem;
	}
}

.home-page {
	padding: 0.8125rem 5.625rem 0;

	app-header {
		position: relative;
	}
}

// account detail padding on large device
.set-padding {
	padding-left: 4.375rem !important;
	padding-right: 4.375rem !important;
}

.custom-ion-button {
	text-transform: uppercase;
	background: var(--ion-color-primary);
	--background: var(--ion-color-primary);
	font-weight: 600;
	font-size: 0.8125rem;
	line-height: 1rem;
	text-align: center;
	letter-spacing: 0.4px;
	color: var(--ion-color-primary-white);
	--box-shadow: none;
	--border-radius: 0;
}

.rotate-arrow {
	transform: rotate(180deg);
}


// initial pages such as login, register etc
.initial-pages {
	background-image: url('assets/icon/login-bg.svg');
	background-repeat: no-repeat;
	background-position: right;
	background-size: 33% 100%;
	background-color: var(--ion-color-gray-20);
	height: 100%;
	// padding-left: 7.5rem ;
	padding-left: 7%;
	padding-right: 10%;
	padding-top: 0.3125rem;
	overflow: auto;

	.heading-label {
		font-size: 2.625rem;
		color: var(--ion-color-primary-black);
		font-weight: bold;
	}

	.form-container {
		width: 62%;
		min-width: 440px;
		max-width: 600px;

		.form-inputs {
			padding-bottom: 0.75rem;

			ion-item {
				.country-code {
					display: none;

				}
			}

			ion-item.item-has-value,
			ion-item.item-has-focus {
				.country-code {
					display: flex;

				}
			}

			ion-label {
				text-transform: uppercase;
			}
		}

		hr {
			border-bottom: 1px solid #D5DADC;
			height: 0;
			margin: 1.5rem 0;
		}

		.register-bottom-container {
			border-top: 1px solid #D5DADC;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 1.875rem;
			padding-top: 1.5rem;

			.cancel {
				font-weight: 400;
				font-size: 0.9375rem;
				line-height: 1.125rem;
				letter-spacing: 0.4px;
				text-transform: uppercase;
				color: var(--ion-color-secondary-black);
				cursor: pointer;

				&:hover {
					font-weight: 600;
				}
			}

			.register-button {
				ion-button {
					--background: var(--ion-color-primary);
					font-weight: 600;
					font-size: 0.9375rem;
					line-height: 1.125rem;
					letter-spacing: 0.4px;
					--box-shadow: none;
					--border-radius: 0;
					height: 46px;
					--padding-start: 2rem;
					--padding-end: 2rem;
				}
			}
		}
	}

	ion-button {
		--background: var(--ion-color-primary) !important;
	}
}

.cursor-pointer {
	cursor: pointer;
}

input:-internal-autofill-selected {
	background-color: transparent !important;
}

.custom-accordion-group {
	ion-accordion {
		p {
			margin-top: 0;
		}
	}
}

.custom-accordion-group:last-child {
	margin-bottom: 0 !important;
}

// Date popover ------------------------->
#datePopover {
	&::part(content) {
		top: 29vh;
		margin: 0 auto;
		left: 0 !important;
		right: 0 !important;
		transform-origin: left top;
		min-width: fit-content;
		max-width: fit-content;
	}
}

// Date popover ------------------------->
#dashboardDate-en {
	&::part(content) {
		top: 45vh;
		right: 1px !important;
		height: 340px;

	}
}

// Date popover ------------------------->
#dashboardDate-ar {
	&::part(content) {
		top: 45vh;
		left: 40px !important;
		height: 340px;

	}
}

.disable-select {
	pointer-events: none !important;
	// color: #ccc !important;
}

.cta-button {
	padding-bottom: 0.9375rem !important;

	ion-button {
		margin-top: 0.75rem;
	}
}

.search-div {
	align-self: center;

	ion-item {
		border: 1px solid var(--ion-color-primary-border);
		font-size: 0.75rem;
		padding: 0.1875rem 0;
		border-radius: 0;
		height: 38px;
		--min-height: 1.25rem !important;
		width: 271px;

		.sc-ion-input-md-h {
			--padding-top: 0.4375rem;
		}
	}

	ion-input {
		min-width: 275px;
		--min-height: 50px !important;
		min-height: 50px !important;

	}
}

.filter-div {
	.search-icon {
		align-self: center;
		margin: 0.25rem 2.625rem 0 0;
		cursor: pointer;
	}

}


.section-block {
	display: flex;
	align-items: center;
}

// initial pages such as login, register etc
.initial-pages-login {
	background-image: url('/assets/icon/login-bg.svg');
	background-repeat: no-repeat;
	background-position: right;
	background-color: var(--ion-color-gray-20);
	background-size: 33% 100%;
	height: 100vh;
	// padding-left: 7.5rem ;
	padding-left: 7%;
	padding-right: 10%;
	padding-top: 0.3125rem;
	overflow: auto;
	align-items: center;
	display: flex;
	flex-direction: row;

	.heading-label {
		font-size: 2.625rem;
		color: var(--ion-color-primary-black);
		font-weight: bold;
	}

	.form-container {
		width: 62%;
		min-width: 440px;
		max-width: 600px;

		.form-inputs {
			padding-bottom: 0.75rem;

			ion-item {
				.country-code {
					display: none;

				}
			}

			ion-item.item-has-value,
			ion-item.item-has-focus {
				.country-code {
					display: flex;

				}
			}

			ion-label {
				text-transform: uppercase;
			}
		}

		hr {
			border-bottom: 1px solid #D5DADC;
			height: 0;
			margin: 1.5rem 0;
		}

		.register-bottom-container {
			border-top: 1px solid #D5DADC;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 1.875rem;
			padding-top: 1.5rem;

			.cancel {
				font-weight: 400;
				font-size: 0.9375rem;
				line-height: 1.125rem;
				letter-spacing: 0.4px;
				text-transform: uppercase;
				color: var(--ion-color-secondary-black);
				padding-right: 2.625rem;
				cursor: pointer;

				&:hover {
					font-weight: 600;
				}
			}

			.register-button {
				ion-button {
					--background: var(--ion-color-primary);
					font-weight: 600;
					font-size: 0.9375rem;
					line-height: 1.125rem;
					letter-spacing: 0.4px;
					--box-shadow: none;
					--border-radius: 0;
					height: 46px;
					--padding-start: 2rem;
					--padding-end: 2rem;
				}
			}
		}
	}

	ion-button {
		--background: var(--ion-color-primary) !important;
	}
}

.rotate-180 {
	transform: rotate(180deg) !important;
}


.pagination-wrapper {
	max-width: 1400px;
	margin: auto;
	text-align: center;
}

.pagination-div {
	align-items: center;
}

.pagination-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin: 1.25rem 0;

	&-tile {
		width: 33%;
		padding: 0;
	}
}

.perpage-dropdown {
	display: flex;
	align-items: center;
	--background: var(--ion-color-sidemenu-background);
	background: var(--ion-color-sidemenu-background) !important;
	width: fit-content;
	padding: 0 10px;
	border-radius: 8px;

	ion-item {
		font-weight: 600;
		font-size: 0.875rem;
		letter-spacing: 0;
		color: var(--ion-color-primary-black);
		--background: var(--ion-color-sidemenu-background);
		opacity: 1;
	}
}

.perpage-title {
	font-size: 0.875rem;
	text-transform: lowercase;
	letter-spacing: 0;
	color: var(--ion-color-primary-black);
	opacity: 1;
	padding-left: 10px;
}

.cover-image {
	outline: 4px solid var(--ion-app-primary);
}

ion-select.LTR::part(icon) {
	opacity: 1;
	color: transparent;
	margin-left: 1rem;
	background: url('./assets/icon/down-arrow-dark.svg') no-repeat center;
}

ion-select.RTL::part(icon) {
	opacity: 1;
	color: transparent;
	margin-right: 1rem;
	background: url('./assets/icon/down-arrow-dark.svg') no-repeat center;
}

.pagination-col {
	text-align: left;
}

// .custom-dropdown-icon {
// 	ion-select::part(icon) {
// 		background-color: var(--ion-black-primary);
// 		-webkit-mask-image: url('/assets/icon/down-arrow-dark.svg');
// 		mask-image: url('/assets/icon/down-arrow-dark.svg');
// 		color: transparent !important;
// 		opacity: 1 !important;
// 		width: 1.0625rem !important;
// 		height: 1.0625rem !important;
// 	}
// }

.hide-selected-text {
	ion-select::part(text) {
		visibility: hidden;
	}
}

.select-input {

	.checkmark-icon {
		background: url("./assets/icon/checkmark-circle-outline.svg");
		background-size: 1.25rem;
		background-repeat: no-repeat;
		padding: 0 1.6875rem;
	}

	.cross-icon {
		background: url("./assets/icon/close-circle-outline.svg");
		background-size: 1.25rem;
		background-repeat: no-repeat;
		padding: 0 1.6875rem;
	}

	.icon-color-change {
		filter: invert(86%) sepia(8%) saturate(14%) hue-rotate(47deg) brightness(92%) contrast(97%);
	}

	.ng-value {

		.category,
		.sub-class,
		.classes {
			background: none;
			margin: 0;
			padding: 0
		}
	}
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
	color: rgba(0, 0, 0, 0.87) !important;
	cursor: default !important;

}

.status-col-ng-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
	color: #ccc !important;
}

.tree-dd {
	ion-item.arrow-icon .sc-ion-select-popover-md {
		background: url("./assets/icon/return-down-forward-outline.svg");
		background-size: 1.25rem;
		background-repeat: no-repeat;
		padding: 0 1.6875rem;
	}

	ion-item {
		ion-label {
			padding-right: 0 !important;
		}

		ion-radio,
		ion-checkbox {
			// ion-checkbox if dropdown multi-select
			width: 0 !important;
			margin: 0 !important;
			padding: 0 !important;
		}

		ion-checkbox {
			// ion-checkbox if dropdown multi-select
			opacity: 0 !important;
		}
	}

	ion-item.arrow-icon.classes .sc-ion-select-popover-md {
		margin-left: 0.625rem;
	}

	ion-item.arrow-icon.sub-class .sc-ion-select-popover-md {
		margin-left: 1.25rem;
	}
}

.group ion-label {
	padding-left: 0.3125rem !important;
	color: #ccc !important;
}

ion-checkbox {
	--background-checked: var(--ion-color-primary-white);
	--border-color: var(--ion-color-primary-border);
	--border-color-checked: var(--ion-color-primary-border);
	--checkmark-color: var(--ion-color-primary-contrast);
	--size: 1.25rem;
	--border-width: 1px;
	--border-radius: 0;
	vertical-align: bottom;
}

//ng-select css
.ng-select.ng-select-disabled>.ng-select-container {
	background-color: transparent !important;

	.ng-value-container,
	.ng-arrow-wrapper {
		cursor: not-allowed !important;
	}
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
	background: var(--ion-light-grey) !important;
	padding: 0.125rem 0.625rem;
	cursor: not-allowed !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	padding: 0.125rem 0.625rem;
}

.ng-select.subclass-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	padding: 0.125rem 0.25rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
	color: #38C2D4 !important;
	background-image: url("/assets/icon/dd_close.svg");
	background-repeat: no-repeat;
	background-position: center;
}

//tootip css
.more-info-tooltip {
	img {
		cursor: pointer;
	}
}

.more-info-tooltip .more-info-tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.4375rem 0.5rem;
	top: 1.75rem;
	left: 0%;
	font-size: 0.75rem;
	position: absolute;
	z-index: 999999;
	word-break: break-word;
	max-width: 400px;
	max-height: 100px;
	overflow: auto;
}

.more-info-tooltip:hover .more-info-tooltiptext {
	visibility: visible;
}

.table {
	.table-body {
		.table-row {
			td {
				img {
					height: 60px;
					width: auto;
				}

				.edit-img-icon,
				.edit,
				.delete-icon {
					height: 16px;
					width: auto;
					padding-left: 5px;
					padding-right: 5px;
				}

				a {
					.anticon {
						font-size: 18px;
					}

					i {
						font-size: 18px;
					}
				}
			}
		}
	}
}

.listing-table {
	.table {
		width: 100%;
		border-collapse: separate;

		.column-heading {
			border-bottom: 1px solid var(--ion-color-primary-border);

		}

		.table-body {
			.table-row {
				td {
					border-bottom: 1px solid var(--ion-color-primary-border);
					background: var(--ion-color-gray-20) !important;
				}
			}
		}
	}
}

// listing style
.common-listing {
	.products-list-container {
		.listing-header {
			display: flex;
			justify-content: space-between;
			padding: 0.3125rem 0 1.5625rem 0;

			.title-div {
				font: normal normal bold 1.75rem /28px Noto Sans;
				color: var(--ion-color-primary-black);
				text-transform: capitalize
			}

			.search-and-filter {
				display: flex;
				justify-content: flex-end;

				.filter-div {
					display: flex;

					.filter-icon {
						display: flex;
						align-items: center;
						padding: 0 0.5625rem;
						cursor: pointer;
					}

					.filter-label {
						cursor: pointer;
						font-weight: 600;
						font-size: 0.875rem;
						line-height: 1.0625rem;
						display: flex;
						align-items: center;
						letter-spacing: 0.3px;
						text-transform: uppercase;
						color: var(--ion-color-primary);
					}
				}
			}
		}

		.listing-table {
			padding: 0 0 1.25rem;
			overflow-x: auto;

			.table {
				width: 100%;

				.column-heading {
					font-weight: 400;
					font-size: 1.125rem;
					line-height: 1rem;
					text-transform: uppercase;
					color: var(--ion-color-secondary-black);
					padding: 0.75rem 0.5625rem 0.75rem 0;
					cursor: pointer;
				}

				.status-col {
					max-width: 110px;
					width: 100px;
				}

				.ship-to-col {
					min-width: 100px;
					max-width: 244px;
				}

				.product-id {
					font-size: 1rem;
					font-weight: bold;
					letter-spacing: 0;
					color: var(--ion-color-primary-black);
					opacity: 1;
					margin-left: 0.375rem;
				}

				.table-body {
					font-size: 1rem;
					letter-spacing: 0;
					color: var(--ion-color-primary-black);
					opacity: 1;

					.table-row {
						border-bottom: 1px solid var(--ion-color-primary-border);

						td {
							padding: 0.4375rem 0.5625rem 0.1875rem 0rem;
							font-weight: 400;
							font-size: 1rem;
							line-height: 1.375rem;
							color: var(--ion-color-primary-black);
						}

						.right-arrow {
							display: flex;
							align-items: center;
						}

					}
				}
			}
		}

		.mobile-view-listing {
			.order-container {
				.order-card {
					background: var(--ion-color-primary-white);
					border: 1px solid var(--ion-color-primary-border);
					border-radius: 2px;
					margin: 0.625rem 0;

					.order-card-col {
						padding-top: 0.9375rem;
					}

					.grid-label {
						font-weight: 400;
						font-size: 0.6875rem;
						line-height: 0.8125rem;
						text-transform: uppercase;
						color: var(--ion-color-secondary-black);
					}

					.grid-values {
						padding-top: 0.1875rem;
						font-weight: 400;
						font-size: 1rem;
						line-height: 1.1875rem;
						color: var(--ion-color-secondary-blue);
					}
				}

				.mobile-right-arrow {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

	}

	.status-btn,
	.pending,
	.shipped,
	.canceled {
		font-weight: 400;
		font-size: 0.8375rem;
		line-height: 1.125rem;
		border-radius: 4px;
		width: 100px;
		height: 2.125rem;
		text-transform: unset;
	}

	.pending {
		background: rgba(109, 207, 246, 0.2);
		color: var(--ion-color-primary-blue);
	}

	.shipped {
		background: rgba(172, 219, 97, 0.25);
		color: var(--ion-color-green-100);
	}

	.canceled {
		background: rgba(233, 75, 75, 0.1);
		color: var(--ion-color-primary);
	}

	.shipping,
	.partially-shipped {
		background: rgba(255, 121, 23, 0.17);
		color: var(--ion-color-primary-orange);
	}

	.order-card {
		.status-btn {
			width: auto;
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 0.875rem;
		}
	}

	.justify-end {
		justify-content: flex-end;
	}

	.justify-start {
		justify-content: flex-start;
	}

	.mass-upload-btn {
		width: 171px;
		height: 46px;
		background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
		opacity: 1;
		font-weight: bold;
		font-size: 0.9375rem;
		letter-spacing: 0;
		color: var(--ion-color-primary-white);
		text-transform: uppercase;
		opacity: 1;
	}

	.products-number-div {
		font-weight: bold;
		letter-spacing: 0;
		color: var(--ion-color-primary-black);
		opacity: 1;
		font-size: 0.875rem;
	}

	.edit-img-icon {
		cursor: pointer;
	}

	.search-icon {
		align-self: center;
		margin: 0.25rem 1.25rem 0 0;
		cursor: pointer;
	}

	.product-checkbox {
		--opacity: 1;
		--border-color: var(--ion-color-primary-border);
		--border-width: 1px;
	}

	.thead {
		border-bottom: 1px solid var(--ion-color-primary-border);
		font-weight: 500;
		font-size: 0.75rem;
		letter-spacing: 0;
		color: var(--ion-color-secondary-black);
		text-transform: uppercase;
		opacity: 1;
	}

	.pagination-wrapper {
		max-width: 1400px;
		margin: auto;
		text-align: center;
	}

	.pagination-div {
		align-items: center;
	}

	.pagination-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin: 1.25rem 0;

		&-tile {
			width: 33%;
			padding: 0;
		}
	}

	.perpage-dropdown {
		display: flex;
		align-items: center;

		// position: absolute;
		// bottom: 2%;
		ion-item {
			font-size: 0.875rem;
			letter-spacing: 0;
			color: var(--ion-color-primary-black);
			opacity: 1;
		}
	}

	.perpage-title {
		font-size: 0.875rem;
		letter-spacing: 0;
		color: var(--ion-color-primary-black);
		opacity: 1;
		padding-left: 10px;
	}

	ion-select::part(icon) {
		opacity: 1;
		margin-left: 1rem;
		color: transparent;
		background: url('./assets/icon/down-arrow-dark.svg') no-repeat center;
	}

	.pagination-col {
		text-align: left;
	}

}

.font-bold {
	font-weight: bold !important;
}

.search-icon.RTL {
	align-self: center;
	margin: 0.25rem 0 0 2.625rem;
	cursor: pointer;
}

.search-icon.LTR {
	align-self: center;
	margin: 0.25rem 2.625rem 0 0;
	cursor: pointer;
}

.sticky-column {
	position: sticky !important;
	right: -2px;
	background: #FFFFFF;

	&:after {
		box-shadow: inset -10px 0 10px -8px #00000026;
		bottom: -1px;
		left: 0;
		transform: translate(-100%);
		position: absolute;
		width: 1.875rem;
		top: 0;
		pointer-events: none;
		content: "";
	}
}

.sticky-header {
	position: sticky !important;
	right: -2px;
	background: var(--ion-color-secondary-shade);

	&:after {
		box-shadow: inset -10px 0 10px -8px #00000026;
		bottom: -1px;
		left: 0;
		transform: translate(-100%);
		position: absolute;
		width: 1.875rem;
		top: 0;
		pointer-events: none;
		content: "";
	}
}

.edit-featured-product-modal {
	--height: unset;
}

.vat-history-modal {
	--height: unset;
	--max-height: 500px;
}

.ionic5-star-rating {
	cursor: default !important;

	button {
		cursor: default !important;
	}
}

.two-fa-modal {
	--height: unset;
	align-items: baseline;
	padding-top: 5.3125rem;
}

.approval-modal {
	backdrop-filter: blur(10px);
	--width: 951px;
	--height: 375px;
}

.preview-modal {
	backdrop-filter: blur(10px);
	--width: calc(100vw - 150px);
	--max-width: 1600px;
	--height: calc(100vh - 150px);
	--max-height: 1080px;
}

.mass-modal {
	backdrop-filter: blur(10px);
	--width: 900px;
	--height: 620px;
}

.upload-image-modal {
	--width: 560px;
	--height: 470px;
}

.change-password-modal {
	--width: 560px;
	--height: 470px;
}




[dir="rtl"] {
	.order-detail-grid {
		.order-detail-row {
			.order-detail-left-col {
				padding: 1.875rem 0 0 3.75rem !important;
			}
		}
	}

	.common-listing {

		td,
		th {
			text-align: right
		}
	}

	.sticky-column {
		position: sticky;
		left: -2px;
		background: #FFFFFF;

		&:after {
			box-shadow: inset -10px 0 10px -8px #00000026;
			bottom: -1px;
			right: 0;
			transform: translate(0%);
			position: absolute;
			width: 1.875rem;
			top: 0;
			pointer-events: none;
			content: "";
		}
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
		color: rgba(0, 0, 0, 0.87) !important;
		cursor: default !important;

	}

	.select-input {

		.category,
		.sub-class,
		.classes {
			background: url("./assets/icon/return-down-back-outline.svg");
			background-size: 1.25rem;
			background-repeat: no-repeat;
			padding: 0 1.6875rem;
			background-position: right;
		}

		.ng-value {

			.category,
			.sub-class,
			.classes {
				background: none;
				margin: 0;
				padding: 0
			}
		}
	}

	.tree-dd {

		ion-item.arrow-icon .sc-ion-select-popover-md {
			background: url("./assets/icon/return-down-back-outline.svg");
			background-size: 1.25rem;
			background-repeat: no-repeat;
			padding: 0 1.6875rem;
			background-position: right;
		}

		ion-item {
			ion-label {
				padding-left: 0 !important;
			}

			ion-radio {
				width: 1px !important;
				margin: 0 !important;
				padding: 0 !important;
			}
		}

		ion-item.arrow-icon.category .sc-ion-select-popover-md {
			padding-right: 1.6875rem !important;

		}

		ion-item.arrow-icon.classes .sc-ion-select-popover-md {
			padding-right: 1.6875rem !important;
			margin-right: 0.625rem !important;
			margin-left: 0 !important;
		}

		ion-item.arrow-icon.sub-class .sc-ion-select-popover-md {
			padding-right: 1.6875rem !important;
			margin-left: 0 !important;
			margin-right: 1.25rem !important;
		}
	}

	.inventory-management-container {
		.listing-table {
			.table {
				.column-heading {
					text-align: right !important;
				}

				.table-body {
					.table-row {
						td {
							text-align: right;
						}

					}
				}
			}
		}

	}
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
	cursor: pointer !important;
}

.ng-select.ng-select-disabled>.ng-select-container .ng-value-container .ng-input>input,
.ng-select.ng-select-disabled>.ng-select-container {
	cursor: not-allowed !important;
}

.bg-image-position-right {
	background-position: right;
}

.bg-image-position-left {
	background-position: left;
}

.sorting {
	margin-left: 20%;
	position: relative;
	top: -3px;
}

.payoutHistoryDetails-modal {
	&::part(content) {
		width: 1000px !important;
		max-height: 501px !important;
	}

	.details-wrapper {
		padding: 1.875rem !important;
	}
}

.product-discount-modal {
	&::part(content) {
		width: 80%;
		height: 99%;
	}

	.ion-page {
		background: var(--ion-color-primary-white);
		font-size: 0.875rem;

		.details-wrapper {
			padding: 2.125rem 3.75rem 3.75rem 3.75rem;

			.close {
				position: absolute;
				right: 0.6875rem;
				top: 0;
				font-size: 1.5rem;
				font-weight: bold;
				cursor: pointer;
			}

			.heading {
				font-size: 1.75rem;
				font-weight: bold;
				margin-bottom: 1.5625rem;
				line-height: 1.75rem;
				text-align: center;
				color: #434345;
				opacity: 1;
			}

			.cancel-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 1rem 0 0.625rem;

				.cancel {
					margin-right: 2.5rem;
					text-transform: uppercase;
					font-size: 1rem;
					cursor: pointer;
					font-weight: bold;
					letter-spacing: 0;
					color: #456371;
					text-transform: uppercase;
					opacity: 1;
				}
			}

			.send-block {
				text-align: right;
			}
		}
	}
}

.terms-conditions-modal {
	&::part(content) {
		width: 80%;
		height: 99%;
	}

	.ion-page {
		background: var(--ion-color-primary-white);
		font-size: 0.875rem;

		.details-wrapper {
			padding: 2.125rem 3.75rem 3.75rem 3.75rem;

			.close {
				position: absolute;
				right: 0.6875rem;
				top: 0;
				font-size: 1.5rem;
				font-weight: bold;
				cursor: pointer;
			}

			.heading {
				font-size: 1.75rem;
				font-weight: bold;
				margin-bottom: 1.5625rem;
				line-height: 1.75rem;
				text-align: center;
				color: #434345;
				opacity: 1;
			}

			.cancel-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 1rem 0 0.625rem;

				.cancel {
					margin-right: 2.5rem;
					text-transform: uppercase;
					font-size: 1rem;
					cursor: pointer;
					font-weight: bold;
					font-size: 0.9375rem;
					letter-spacing: 0;
					color: #456371;
					text-transform: uppercase;
					opacity: 1;
				}
			}

			.send-block {
				text-align: right;
			}
		}
	}
}

// details-modal
.details-modal,
.payoutHistoryDetails-modal {
	&::part(content) {
		width: 560px;
		min-height: 200px;
		--height: auto;
	}

	.ion-page {
		background: var(--ion-color-primary-white);
		font-size: 0.875rem;

		.details-wrapper {
			padding: 2.125rem 3.75rem 3.75rem 3.75rem;

			.close {
				position: absolute;
				right: 0.6875rem;
				top: 0;
				font-size: 1.5rem;
				font-weight: bold;
				cursor: pointer;
			}

			.heading {
				font-size: 1.75rem;
				font-weight: bold;
				margin-bottom: 1.5625rem;
				line-height: 1.75rem;
				text-align: center;
				color: #434345;
				opacity: 1;
			}

			.cancel-block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 1rem 0 0.625rem;

				.cancel {
					text-transform: uppercase;
					font-size: 1rem;
					cursor: pointer;
					font-size: 0.9375rem;
					font-weight: bold;
					letter-spacing: 0;
					color: #456371;
					text-transform: uppercase;
					opacity: 1;
				}
			}

			.send-block {
				text-align: right;
			}
		}
	}
}

.details-modal {
	.ion-page {
		.details-wrapper {
			padding: 1.5625rem 3.75rem 1.25rem 3.75rem !important;
		}
	}
}



.mobile-menu {
	display: none;
}

.account-menu-header {
	display: none;
}

.variation-accordian {
	.ion-accordion-toggle-icon {
		display: none !important;
	}

	.iconColor {
		color: var(--ion-app-primary) !important;
	}
}

.mobile-view-listing {
	display: none;
}

.mobile-user-block-ltr {
	display: none;
}

.mobile-user-block-rtl {
	display: none;
}

// language drop down
.language-dropdown,
.filters-dropdown {
	&::part(content) {
		width: 120px;
		border-radius: 0;
		margin-top: 0.625rem;
	}

	.item-radio-checked {
		--background: var(--ion-color-primary);
		--background-focused: var(--ion-color-primary);
		--background-focused-opacity: 0.2;
		--background-hover: var(--ion-color-primary);
		--background-hover-opacity: 0.12;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}
}

.custom-select-dd {
	&::part(content) {
		border-radius: 0;
		max-height: 300px;
	}

	.item-radio-checked {
		--background: var(--ion-color-primary);
		--background-focused: var(--ion-color-primary);
		--background-focused-opacity: 0.2;
		--background-hover: var(--ion-color-primary);
		--background-hover-opacity: 0.12;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}

	ion-item.sc-ion-select-popover-md {
		text-transform: capitalize;
	}
}

.filters-dropdown {
	&::part(content) {
		width: 200px;
	}
}

.warehouse-dropdown {
	&::part(content) {
		max-height: 300px;
	}
}

.per-page-dd {
	&::part(content) {
		width: 70px;
	}

	&::part(backdrop) {
		background-color: transparent !important;
	}

	ion-item.sc-ion-select-popover-md {
		--inner-padding-end: 0 !important;
	}
}

.common-input {
	ion-label.label-floating {
		color: var(--ion-color-secondary-black) !important;
	}

	ion-item {
		border: 1px solid var(--ion-color-primary-border);
		border-radius: 2px;
		--highlight-color-focused: var(--ion-color-secondary-blue);
		--min-height: 50px;

		ion-input {
			--placeholder-color: var(--ion-color-secondary-black);
			--placeholder-font-weight: 400;
			--color: var(--ion-color-primary-black);
		}

		font-weight: 500;
	}

	img {
		margin: 0.125rem 1rem 0.125rem 0 !important;
	}


}

.item-interactive.item-has-focus {
	border: 1px solid var(--ion-color-secondary-blue) !important;
	box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
	border-radius: 2px !important;
}


.desktop-searchbar-ltr {
	.searchbar-search-icon.sc-ion-searchbar-md {
		right: 0 !important;
		left: auto !important;
		top: 0 !important;
		height: 45.5px !important;
		width: 46px !important;
		text-transform: capitalize !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		height: 46px;
		padding-inline-start: 1.375rem;
		padding-inline-end: 4.375rem;
		--placeholder-color: var(--ion-color-secondary-black);
		--box-shadow: none;
		border: 1px solid var(--ion-color-primary-border);
	}

	.searchbar-clear-button.sc-ion-searchbar-md {
		right: 3.125rem;
	}
}

.desktop-searchbar-rtl {
	.searchbar-search-icon.sc-ion-searchbar-md {
		right: 0 !important;
		left: auto !important;
		top: 0 !important;
		height: 45.5px !important;
		width: 46px !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		height: 46px;
		padding-inline-start: 4.375rem;
		padding-inline-end: 1.375rem;
		--placeholder-color: var(--ion-color-secondary-black);
		--box-shadow: none;
		border: 1px solid var(--ion-color-primary-border);
	}

	.searchbar-clear-button.sc-ion-searchbar-md {
		right: 3.125rem;
	}
}

.desktop-visibility {
	display: block;
}

.mobile-visibility {
	display: none;
}

// not found page
#notfound {
	background-image: url('assets/images/not-found-bg.jpg')
}

.mobile-searchbar {
	.searchbar-search-icon.sc-ion-searchbar-md {
		height: 0.875rem !important;
		width: 0.875rem !important;
		top: 13.5px !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		padding-inline-start: 2.625rem;
		--placeholder-color: var(--ion-color-secondary-black);
		--box-shadow: none;
		border: 1px solid var(--ion-color-primary-border);
		height: 2.625rem;
	}
}

.add-featured-product {
	.paginator {
		.ngx-pagination {
			margin: 0
		}
	}
}

.paginator {
	.ngx-pagination {
		padding: 0;
		font: normal normal bold 0.875rem /21px Noto Sans;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		li {
			color: var(--ion-black-primary);
			opacity: 0.6;
		}

		li:not(.current, .pagination-next, .pagination-previous) {
			background: transparent;
		}

		.current {
			background: var(--ion-app-primary);
			color: var(--ion-color-primary-contrast);
			padding: 0.29rem 0.68rem;
			border-radius: 3px;
			opacity: 1;
		}

		a {
			background: var(--ion-color-primary-contrast);
			padding: 0.29rem 0.68rem;
			margin-right: 9px;
			border-radius: 3px;
		}

		a:hover,
		.ngx-pagination button:hover {
			background: var(--ion-color-primary-contrast);
		}

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url("/assets/icon/prev_arrow.svg");
			top: 0.125rem;
			position: relative;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url("/assets/icon/next_arrow.svg");
			top: 0.125rem;
			position: relative;
		}

		.pagination-previous a,
		.pagination-previous.disabled {
			padding: 1rem 0.7rem;
		}

		.pagination-next a,
		.pagination-next.disabled {
			background: transparent;
			padding: 1rem 0.7rem;
		}

		.pagination-next.disabled,
		.pagination-previous.disabled {
			opacity: 0.3;
		}

	}
}

.paginator-icon-rotate {
	.ngx-pagination {

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url('/assets/icon/prev_arrow.svg');
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url('/assets/icon/next_arrow.svg');
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}
	}
}

.mobile-visibility-in-flex {
	display: none;
}

//File upload
.ngx-file-drop__content {
	color: #456371 !important;
	font-size: 1rem;
	flex-direction: column;

	span {
		color: #38C2D4;
		cursor: pointer;
	}

	img {
		width: 1.5625rem;
	}
}

.ngx-file-drop__drop-zone {
	border: 2px dashed #D5DADC !important;
	border-radius: 0.3125rem !important;
	margin: 1.25rem 0 !important;
}

.upload-table {
	height: 230px;
	overflow-y: auto;

	.d-flex {
		display: flex;
	}

	.fileBox {
		height: 5rem;
		width: 15%;
		margin-right: 0.9375rem;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.wrapper {
		border: 1px solid #D5DADC;
		height: 5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.25rem;
		width: 100%;
		margin-bottom: 0.625rem;

		.fileName {
			font-size: 1rem;
			color: #434345;
			width: 55%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			margin: 0;
		}

		.action {
			display: flex;
			cursor: pointer;
		}

		.size {
			font-size: 0.75rem;
			color: #456371;
		}

		.remove {
			background: none !important;
			font-size: 0.875rem;
			color: #456371;
			font-weight: bold;
			margin-left: 2.5rem;
		}

		.progress-wrap {
			width: 80%;
		}
	}
}

.native-textarea.sc-ion-textarea-md {
	overflow: auto !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

// register now
.register-block {
	background: url('assets/icon/register_bg.svg'), no-repeat;
	background-size: 100% 100%;
}

ion-button {
	--border-radius: 0 !important;
	--box-shadow: none !important;
}

.custom-button {
	padding-top: 2.1875rem;

	ion-button {
		--padding-start: 0;
		--padding-end: 0;
		width: 205px;
		height: 48px;
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.125rem;
		text-transform: uppercase;
		display: flex;
		align-items: center;
	}
}

.warehouse-list {
	max-height: 200px;
	overflow: auto;

	ion-item {
		--min-height: 1.25rem !important;

		ion-input {
			.native-input.sc-ion-input-md {
				--padding-top: 0.3125rem !important;
				--padding-bottom: 0.3125rem !important;

			}
		}
	}
}

.input-clear-icon {
	cursor: pointer;
	margin: 0 !important;
	width: 20px !important;
	height: 20px !important;
	min-width: 20px !important;
}

.status-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.search-div {
	margin-right: 1.25rem;
	align-self: center;

	input {
		border: 1px solid var(--ion-color-primary-border);
		font-size: 0.75rem;
		padding: 0.1875rem 0.625rem;
		border-radius: 0;
	}
}

.status-dropdown {
	background: var(--ion-color-primary-white) 0% 0% no-repeat padding-box;
	border: 1px solid var(--ion-divider);
	opacity: 1;
	height: 38px;
	display: flex;
	--ripple-color: transparent;
	--background-activated: transparent;
	padding: 0;

	ion-item {
		--background-hover: transparent;
		--ripple-color: transparent;
		width: 100%;
		--min-height: 36px;

		ion-select {
			width: 100%;
			--padding-top: 0;
			--padding-bottom: 0;
			--background-hover: transparent;
			--ripple-color: transparent;
		}
	}

	.item-interactive.item-has-focus {
		border: none !important;
	}

	ion-input {
		--padding-bottom: 0;
		--padding-top: 0;
	}
}

.filter-apply-button {
	background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
	opacity: 1;
	font-size: 0.875rem;
	font-weight: bold;
	letter-spacing: 0;
	color: var(--ion-color-primary-white);
	text-transform: uppercase;
	width: 92px;
	height: 38px;
}

.from-date-div,
.to-date-div {
	display: flex;
	align-items: center;
	width: 100%;
}

.from-date-label.RTL,
.to-date-label.RTL {
	margin-left: 0.625rem;
}

.from-date-label.LTR,
.to-date-label.LTR {
	margin-right: 0.625rem;
}

.from-date-class.RTL,
.to-date-class.RTL {
	margin-left: 1.25rem;
}

.from-date-class.LTR,
.to-date-class.LTR {
	margin-right: 1.25rem;
}

.from-date-label,
.to-date-label {
	text-transform: capitalize;
}


// common scss for ng-select
@import 'src/theme/ng-select.scss';

// custamize css for ng-select
.select-label-text {
	position: absolute;
	top: 0.0625rem;
	border: 1px solid #ccc;
	border-bottom: 0 !important;
	width: 100%;
	border-radius: 4px;
	height: 2rem;
	line-height: 2rem;

	.pl-10 {
		padding-left: 0.625rem;
	}
}

.ng-select.select-input {
	position: relative;
}

.select-input {
	.select-list-checkbox {
		display: flex;
		align-items: center;

		input {
			width: 1.25rem;
			height: 1.25rem;
			margin-right: 0.9375rem;
		}
	}

}


.ng-select.select-input .ng-select-container {
	height: 2rem !important;
	margin-top: 1.75rem !important;
	border-top: 0 !important;
	background-color: var(--ion-color-gray-20);
}

.order-ng-select-container {
	.ng-select.ng-select-single .ng-select-container {
		height: 38px !important;
	}
}

.ng-select .ng-select-container .ng-value-container {
	align-items: center;
	padding-left: 0.625rem;
}

.ng-select-dropdown {
	.ng-select.ng-select-opened>.ng-select-container {
		background: #fff;
		border-color: #b3b3b3 #ccc #d9d9d9;
	}

	.ng-select.ng-select-opened>.ng-select-container:hover {
		box-shadow: none;
	}

	.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
		border: solid #434345;
		border-width: 0 2px 2px 0;
		transform: rotate(-135deg);
	}

	.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
		border-color: transparent transparent #434345;
	}

	.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}

	.ng-select.ng-select-opened.ng-select-right>.ng-select-container {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.ng-select.ng-select-opened.ng-select-left>.ng-select-container {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.ng-select.ng-select-disabled>.ng-select-container {
		background-color: #f9f9f9;
	}

	.ng-select .ng-has-value .ng-placeholder {
		display: none;
	}

	.ng-select .ng-select-container {
		height: 50px !important;
		border-radius: 4px;
		letter-spacing: 0 !important;
		color: var(--ion-color-secondary-black) !important;
		text-transform: uppercase !important;
		opacity: 1 !important;
		font-size: 0.75rem !important;
		background-color: var(--ion-color-gray-20) !important;
		border-color: var(--ion-color-gray-50) !important;
	}

	.ng-select .ng-select-container:hover {
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	}

	.ng-select .ng-select-container .ng-value-container {
		align-items: center;
		padding-left: 0.9375rem;
	}

	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: var(--ion-color-secondary-black);
	}

	.ng-select.ng-select-single .ng-select-container {
		height: 36px;
	}

	.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
		top: 1.3125rem;
		left: 0;
		padding-left: 0.625rem;
		padding-right: 1.25rem;
	}

	[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
		padding-right: 0.625rem;
		padding-left: 3.125rem;
	}

	.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
		background-color: #f9f9f9;
		border: 1px solid #e6e6e6;
	}

	.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
		padding: 0 0.3125rem;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
		padding-top: 0.3125rem;
		padding-left: 0.4375rem;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
		padding-right: 0.4375rem;
		padding-left: 0;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
		font-size: 0.9rem;
		margin-bottom: 0.3125rem;
		color: #434345;
		background-color: #ebf5ff;
		border-radius: 2px;
		margin-right: 0.3125rem;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
		margin-right: 0;
		margin-left: 0.3125rem;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
		background-color: #f9f9f9;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
		padding-left: 0.3125rem;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
		padding-left: 0;
		padding-right: 0.3125rem;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
		display: inline-block;
		padding: 0.0625rem 0.0625rem 0.3125rem;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
		display: inline-block;
		padding: 0.0625rem 0.3125rem;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
		background-color: #d1e8ff;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
		border-right: 1px solid #b8dbff;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
		border-left: 1px solid #b8dbff;
		border-right: none;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
		border-left: 1px solid #b8dbff;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
		border-left: 0;
		border-right: 1px solid #b8dbff;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
		padding: 0 0 0.1875rem 0.1875rem;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
		padding: 0 0.1875rem 0.1875rem 0;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
		color: #000;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
		top: 0.3125rem;
		padding-bottom: 0.3125rem;
		padding-left: 0.1875rem;
	}

	[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
		padding-right: 0.1875rem;
		padding-left: 0;
	}

	.ng-select .ng-clear-wrapper {
		color: var(--ion-color-secondary-black)
	}

	.ng-select .ng-clear-wrapper:hover .ng-clear {
		color: #d0021b;
	}

	.ng-select .ng-spinner-zone {
		padding: 0.3125rem 0.3125rem 0 0;
	}

	[dir="rtl"] .ng-select .ng-spinner-zone {
		padding: 0.3125rem 0 0 0.3125rem;
	}

	.ng-select .ng-arrow-wrapper {
		padding-bottom: 0.375rem;
	}

	.ng-select .ng-arrow-wrapper:hover .ng-arrow {
		border-top-color: #666;
	}

	.ng-select .ng-arrow-wrapper .ng-arrow {
		border: solid #434345;
		border-width: 0 2px 2px 0;
		padding: 0.1875rem;
		transform: rotate(45deg);
	}

	.ng-dropdown-panel {
		background-color: #fff;
		border: 1px solid #ccc;
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
		left: 0;
	}

	.ng-dropdown-panel.ng-select-top {
		bottom: 100%;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-bottom-color: #e6e6e6;
		margin-bottom: -1px;
	}

	.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.ng-dropdown-panel.ng-select-right {
		left: 100%;
		top: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-color: #e6e6e6;
		margin-bottom: -1px;
	}

	.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
		border-top-right-radius: 4px;
	}

	.ng-dropdown-panel.ng-select-bottom {
		top: 100%;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top-color: #e6e6e6;
		margin-top: -1px;
	}

	.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	.ng-dropdown-panel.ng-select-left {
		left: -100%;
		top: 0;
		border-top-left-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-color: #e6e6e6;
		margin-bottom: -1px;
	}

	.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
		border-top-left-radius: 4px;
	}

	.ng-dropdown-panel .ng-dropdown-header {
		border-bottom: 1px solid #ccc;
		padding: 0.3125rem 0.4375rem;
	}

	.ng-dropdown-panel .ng-dropdown-footer {
		border-top: 1px solid #ccc;
		padding: 0.3125rem 0.4375rem;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
		user-select: none;
		padding: 0.5rem 0.625rem;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.54);
		cursor: pointer;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
		cursor: default;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
		background-color: var(--ion-color-gray-30);
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
		color: rgba(0, 0, 0, 0.54);
		background-color: var(--ion-color-gray-40);
		font-weight: 600;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		background-color: var(--ion-color-gray-20);
		color: rgba(0, 0, 0, 0.87);
		padding: 0.5rem 0.625rem;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
		color: #434345;
		background-color: var(--ion-color-gray-40);
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
		font-weight: 600;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		background-color: var(--ion-color-gray-30);
		color: #434345;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
		color: #ccc;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
		padding-left: 1.375rem;
	}

	[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
		padding-right: 1.375rem;
		padding-left: 0;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
		font-size: 80%;
		font-weight: 400;
		padding-right: 0.3125rem;
	}

	[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
		padding-left: 0.3125rem;
		padding-right: 0;
	}

	[dir="rtl"] .ng-dropdown-panel {
		direction: rtl;
		text-align: right;
	}

}

.ng-select.region-select .ng-select-container {
	.ng-value-container .ng-placeholder {
		color: var(--ion-color-primary-black);
		font-size: 16px;

		&::after {
			content: " *";
			color: var(--ion-app-primary);
			;
		}
	}
}

.arabic-class.ng-select-dropdown {
	.ng-select-container {
		padding: 1.25rem 0.75rem 1.25rem 0.625rem !important;
	}

	.ng-select .ng-arrow-wrapper {
		padding-right: 0;
		padding-left: 0.75rem;
		width: 0;
	}
}

[dir="rtl"] .ng-select-container {
	padding: 1.25rem 0.3125rem 1.25rem 0.3125rem !important;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
	padding-right: 0.9375rem;
	padding-left: 0;
}

.warehouse-ng-select.ng-select.select-input .ng-select-container {
	margin-top: 0 !important;
	border-top: 1px solid #ccc !important;
}

.warehouse-ng-select.ng-select .ng-select-container {
	border-radius: 0 !important;
	height: 50px;
	min-height: 50px;
}


.warehouse-ng-select.ng-select {
	max-width: 330px;
	min-width: 330px;
	height: 50px;
	min-height: 50px;
}

.subclass-ng-select {
	.ng-select .ng-select-container {
		height: 50px !important;
	}
}

.warehouse-ng-select {
	.ng-select.select-input .ng-select-container {
		margin-top: 0 !important;

	}
}

.mass-upload-ng-select {
	.ng-select.select-input .ng-select-container {
		margin-top: 0 !important;
		border-radius: 0 !important;
		border: none !important;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
		top: unset !important;
	}
}

.filters-dropdown {
	.ng-select.select-input .ng-select-container {
		margin-top: 0 !important;
		border-radius: 0 !important;
		border-top: 1px solid #ccc !important;
	}

	.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
		top: unset !important;
	}
}

.preview-page-ng-select-input {
	position: relative;

	.select-label-text,
	.ng-select .ng-select-container {
		border-radius: 0 !important;
	}

	.ng-arrow-wrapper {
		display: none;
	}
}

.from-date-class,
.to-date-class {
	background: var(--ion-color-primary-white) 0% 0% no-repeat padding-box;
	border: 1px solid var(--ion-divider);
	opacity: 1;
	height: 38px;
	width: 100%;

	ion-icon {
		width: 1.125rem;
		margin-bottom: 0.6875rem;
	}

	ion-input {
		--padding-bottom: 0;
		--padding-top: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 38px;
		max-height: 38px;
		min-height: 38px;
		margin: 0 !important;
	}
}

.apply-button {
	align-self: center;
}

.close-filter-icon {
	width: 1.75rem;
	height: 1.75rem;
	margin-right: 0.4375rem;
	cursor: pointer;
}

.secure-support-block {
	font-size: 0.875rem;
	color: var(--ion-black-primary);
	display: flex;
	justify-content: center;
	margin-bottom: 78px;

	.section {
		display: flex;
		align-items: center;

		img {
			width: 1.875rem;
		}

		.right-side {
			display: flex;
			flex-direction: column;
			margin-left: 1.125rem;

			.title {
				font-size: 1.28rem;
				font-weight: 700;
			}

			.text {
				color: var(--ion-grey1);
			}
		}
	}

	.vertical-line {
		height: auto;
		width: 1px;
		background: #D5DADC;
		margin: 0 4.6875rem;

	}
}

.vertical-separate {
	border-right: 1px solid var(--ion-divider);
}

.in-english {
	display: block !important;
}

.in-arabic {
	display: none !important;
}

.float-right {
	float: right;
}

.download-arrow {
	display: flex;
	align-items: center;
}

.from-date-class,
.to-date-class {
	--min-height: 38px;

	ion-icon {
		margin: auto;
		cursor: pointer;
	}
}

.listing-table {
	overflow-y: auto;
	max-height: 430px;

	ion-toggle {
		--handle-width: 21px;
		/* Adjust handle size */
		--handle-height: 21px;
		zoom: 0.8; //size of switch track

		:disabled {
			pointer-events: unset !important;
			cursor: not-allowed !important;
		}
	}

	.table {
		thead {
			th {
				position: sticky;
				top: 0;
				background: var(--ion-color-gray-20) !important;
			}
		}

		.edit {
			position: static !important;
		}

		.column-heading {
			z-index: 3;
		}
	}

	.table-body {
		td {
			ion-toggle {
				// --handle-width: 1.0625rem;
				// width: 43px;
				// height: 1.3125rem;
			}
		}
	}
}

[dir="rtl"] {
	* {
		text-transform: none !important;
	}

	.common-input img {
		margin: 0.125rem 5rem 0 0.125rem 1rem !important;
	}

	.text-right-rtl {
		text-align: right !important;
	}

	.float-right {
		float: left;
	}

	.arrow-custom-btn-rtl {
		right: unset !important;
		left: 0 !important;
		transform: rotate(180deg) !important;
		padding-top: 0 !important;
	}

	.in-english {
		display: none !important;
	}

	.in-arabic {
		display: block !important;
	}
}


[dir="rtl"] .secure-support-block {
	.section {
		.right-side {
			display: flex;
			flex-direction: column;
			margin-left: 0;
			margin-right: 1.125rem;
		}
	}
}

::-webkit-scrollbar {
	width: 0.75rem;
}

::-webkit-scrollbar:horizontal {
	height: 0.5rem;
}

::-webkit-scrollbar-thumb {
	background-color: var(--ion-divider);
	border-radius: 0.625rem;
}

::-webkit-scrollbar-track {
	background-color: #ffffff;
	border-radius: 0.625rem;
}

.pr-8 {
	padding-right: 0.5rem;
	padding-left: 0;
}

.pl-32 {
	padding-left: 2rem;
	padding-right: 0;
}

.loader-style {
	display: flex;
	min-height: 200px;
	align-items: center;
	justify-content: center;
}

.psw-position {
	position: relative;
}

.sorting-icon {
	cursor: pointer;
	width: 0.75rem;
	height: 0.75rem;
	padding: 0;
	margin: 0 0 -0.125rem 0;
}

[dir="rtl"] {
	ion-accordion-group {
		ion-accordion {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}
		}

		.accordion-expanded {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}

		}
	}

}

.common-input-textarea ion-item {
	border: 1px solid var(--ion-divider);
	--padding-start: 0.875rem;
	--highlight-color-focused: var(--ion-color-secondary-black);
	--min-height: 58px;
	height: 6.75rem;
	--placeholder-color: var(--ion-color-secondary-black);
	--placeholder-font-weight: 400;
	--color: var(--ion-color-primary-black);
}

.countrycode-label {
	position: absolute;
	top: 1.75rem;
}

ion-menu {
	.menu-content {
		background-color: var(--ion-color-sidemenu-background);
	}
}

@media screen and (max-width: 1550px) {
	.initial-pages {
		.heading-label {
			font-size: 39px !important;
		}

		.form-container {
			width: unset;
			min-width: unset;
		}
	}

	.initial-pages-login {
		.heading-label {
			font-size: 39px !important;
		}

		.form-container {
			width: unset;
			min-width: unset;
		}
	}

	.registration-page {
		.main-container {
			.content-section {
				padding-top: 2rem !important;
				justify-content: space-between;

				.left-container {
					padding-left: 4.0625rem;
					width: 45%;
				}

				.banner-container {
					padding-left: 1.25rem !important;
					width: 45%;

					img {
						width: 100% !important;
						min-width: unset !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {

	.home-page {
		padding: 0.8125rem 1.875rem 0;
	}

	.account-details-page {
		padding: 0 3rem 1rem 3rem !important;
	}

	// account detail padding on small device
	.set-padding {
		padding-left: 0.9375rem !important;
		padding-right: 0.625rem !important;
	}

	.bread-crumbs {
		margin: 1.125rem 4.125rem !important;
	}
}

@media screen and (max-width: 1368px) {
	.registration-page {
		.banner-container {
			img {
				left: -70px !important;
			}
		}
	}

	.home-page {
		padding: 0.8125rem 1.875rem 0;
	}

	// account detail padding on small device
	.set-padding {
		padding-left: 0.9375rem !important;
		padding-right: 0.625rem !important;
	}

	.bread-crumbs {
		margin: 1.125rem 4.125rem !important;
	}
}

@media screen and (max-width: 992px) {
	.registration-page {
		.banner-container {
			img {
				left: -40px !important;
			}
		}
	}

	.approval-modal {
		backdrop-filter: blur(10px);
		--width: 750px;
		--height: 350px;
	}

	.preview-modal {
		backdrop-filter: blur(10px);
		--width: 750px;
		--height: 580px;
	}
}

@media screen and (max-width: 768px) {
	.two-fa-modal {
		--height: auto;
		--width: auto;
	}

	.registration-page {
		.banner-container {
			img {
				left: -1.25rem !important;
			}
		}
	}

	.home-page {
		padding: 0;
	}

	.mobile-visibility-in-flex {
		display: flex;
	}

	.approval-modal {
		backdrop-filter: blur(10px);
		--width: 600px;
		--height: 500;
	}

	.preview-modal {
		backdrop-filter: blur(10px);
		--width: 600px;
		--height: 580px;
	}
}


@include respond-between(ipadmin, ipadmax) {
	.home-page {
		padding: 0;
	}

	#twoFaModal {
		--height: auto;
		--width: auto;
	}

	.new-products-container .steps .row-div {
		justify-content: flex-start !important;
	}

	.per-page-dd,
	.language-dropdown {
		ion-radio {
			margin: 0 !important;
		}
	}

	.order-detail-grid .order-detail-row .order-detail-left-col {
		padding: 1.875rem 1rem 0 0 !important;
	}
}

@include respond-between(ipadmax, ipadlandscape) {
	.home-page {
		padding: 0;
	}

	#twoFaModal {
		--height: auto;
		--width: auto;
	}

	.per-page-dd,
	.language-dropdown {
		ion-radio {
			margin: 0 !important;
		}
	}

	.order-detail-grid .order-detail-row .order-detail-left-col {
		padding: 1.875rem 1rem 0 0 !important;
	}
}

.loader-style {
	display: flex;
	min-height: 200px;
	align-items: center;
	justify-content: center;
}

.psw-position {
	position: relative;
}

.sorting-icon {
	cursor: pointer;
	width: 0.75rem;
	height: 0.75rem;
	padding: 0;
	margin: 0 0 -0.125rem 0;
}

[dir="rtl"] {
	ion-accordion-group {
		ion-accordion {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}
		}

		.accordion-expanded {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}

		}
	}

}

.common-input-textarea ion-item {
	border: 1px solid var(--ion-divider);
	--padding-start: 0.875rem;
	--highlight-color-focused: var(--ion-color-secondary-black);
	--min-height: 58px;
	height: 6.75rem;
	--placeholder-color: var(--ion-color-secondary-black);
	--placeholder-font-weight: 400;
	--color: var(--ion-color-primary-black);
}

.countrycode-label {
	position: absolute;
	top: 1.75rem;
}

.isMenuMobile {
	display: none;
}

.isDesktop {
	display: block;
}

.isMobile {
	display: none;
}

.web-hamburger {
	display: block;
}

ion-menu-button {
	filter: invert(1);
}

@include respond-between(ipadmin, ipadmax) {
	.isMobile {
		display: block;
	}

	.web-hamburger {
		display: none;
	}

	.isDesktop {
		display: none;
	}

}

@include respond-below(md) {
	.search-div ion-item {
		width: 100% !important;
	}

	.common-listing .products-list-container .listing-header .title-div {
		font-size: 1.625rem;
	}

	.notification-management-container .listing-header .title-div {
		font-size: 1.625rem !important;
	}

	.notification-category-title {
		font-size: 1.625rem !important;
	}

	.notification-management-container {
		.listing-table {
			.notification-date {
				min-width: unset !important;
			}

			.notification-desc {
				min-width: unset !important;

			}
		}
	}

	.from-date-class.LTR,
	.to-date-class.LTR {
		margin-right: 0px;
	}

	.common-listing .products-list-container .listing-header {
		display: block;
	}

	.products-number-div {
		margin-bottom: 5px;
	}

	.filter-header-ng-select {
		padding-bottom: 15px;
	}

	.registration-container {
		margin-top: -30px;
	}

	.isDesktop {
		display: none;
	}

	.isMobile {
		display: block;
	}

	.web-hamburger {
		display: none;
	}

	.initial-pages-login {
		background: none;
		padding-left: 7%;
		padding-right: 7%;

		.heading-label {
			font-size: 30px !important;
		}
	}

	.heading-label {
		padding-top: 2rem;
		display: flex;
		justify-content: center;
	}

	.company-logo {
		width: 80%;
	}

	.logout-icon {
		margin-right: 10px;
	}
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
	display: contents;
}


.arabic-class ion-label {
	-webkit-transform-origin: right !important;
}

.text-end {
	text-align: right;
}

.primary-btn {
	ion-button {
		--padding-start: 1rem;
		--padding-end: 1rem;
		--background-hover: none;

		&.button-disabled {
			color: var(--ion-color-gray-60) !important;
			--background: var(--ion-color-gray-20) !important;
			--border-color: var(--ion-color-gray-50) !important;
			opacity: 1 !important;

			.text-css {
				color: var(--ion-color-gray-60) !important;
			}
		}

		&:hover {
			--box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
			--border-radius: 4px !important;
		}
	}
}

.secondary-btn {
	ion-button {
		--padding-start: 1rem;
		--padding-end: 1rem;
		--background-hover: var(--ion-color-gray-20);
		--background-hover-opacity: 1;

		&.button-disabled {
			color: var(--ion-color-gray-50) !important;
			--background: var(--ion-color-primary-contrast) !important;
			--border-color: var(--ion-color-gray-50) !important;
			opacity: 1 !important;

			.text-css {
				color: var(--ion-color-gray-60) !important;
			}
		}

		&:hover {
			--border-color: var(--ion-color-gray-50) !important;
		}
	}
}

.tertiary-btn {
	ion-button {
		--padding-start: 1rem;
		--padding-end: 1rem;
		--background-hover: var(--ion-color-gray-20);
		--background-hover-opacity: 1;

		&.button-disabled {
			color: var(--ion-color-gray-50) !important;
			--background: var(--ion-color-primary-contrast) !important;
			--border-color: var(--ion-color-gray-50) !important;
			opacity: 1 !important;

			.text-css {
				color: var(--ion-color-gray-60) !important;
			}
		}

		&:hover {
			--border-color: var(--ion-color-gray-50) !important;
		}
	}
}

ion-item {
	--background: transparent;
	background: transparent;

}

ion-list {
	background: var(--ion-color-gray-20) !important;
}

ion-footer {
	background: var(--ion-color-gray-20);
}

ion-app {
	background-color: var(--ion-color-gray-20);
}

ion-button {
	--border-radius: 4px !important;
	border-radius: 4px;
}

button {
	border-radius: 4px !important;
}

.sc-ion-input-md-s>[slot=start]:last-of-type {
	margin-inline-end: 0.5rem;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
	color: black;
	font-weight: 500;
}

ion-input,
ion-item {
	min-height: 50px !important;
	--min-height: 50px !important;
}

ion-input,
ion-text,
ion-textarea,
ion-label {
	color: black;
}

.ant-rate-star-zero {

	.ant-rate-star-first,
	.ant-rate-star-second {
		color: grey !important;
	}

}